/* @formatter:off */
#lineTable {
  border: 1px solid yellow;
}
.homeT {
  background-color: #cbefad;
}
.homeT .popover {
  width: 400px;
}
.goalT {
  background-color: #efefbe;
}
.goalT .popover {
  width: 400px;
}
.awayT {
  background-color: #efd7b4;
}
.awayT .popover {
  width: 400px;
}
.fontSize11 {
  font-size: 11px !important;
}
.tableSerie {
  text-align: center;
  border-collapse: collapse;
  font-family: Arial;
  font-size: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #c4c0c9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c4c0c9;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #c4c0c9;
}
.tableSerie td,
.tableSerie .tableRowSerie {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c4c0c9;
  border-right-width: 1px;
  border-right-style: solid;
  /*border-right-color: red;*/
  border-right-color: #c4c0c9;
}
.tableSerie td > div,
.tableSerie .tableRowSerie > div {
  padding: 3px;
}
.tableSerie .team {
  text-align: left;
  text-transform: uppercase;
}
.tableSerie td {
  padding: 2px;
}
label.custom-select {
  position: relative;
  display: inline-block;
}
.custom-select select {
  display: inline-block;
  padding: 4px 3px 3px 5px;
  margin: 0;
  font: inherit;
  /* remove focus ring from Webkit */
  line-height: 1.2;
  background: #580e0e;
  color: white;
  border: 0;
  outline: none;
  border-radius: 3px;
}
.custom-select:after {
  border-radius: 0px 3px 3px 0px;
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 60%;
  line-height: 30px;
  padding: 0 10px;
  background: #57252f;
  color: white;
}
.no-pointer-events .custom-select:after {
  content: none;
}
.scrollup {
  position: fixed;
  right: 300px;
  top: 75px;
  background: url("/images/icon_top.png") no-repeat 0px 0px;
  opacity: 0.5;
  display: inline;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}
.scrolldown {
  position: fixed;
  right: 300px;
  bottom: 20px;
  background-color: red;
  background: url("/images/icon_bottom.png") no-repeat 0px 0px;
  opacity: 0.5;
  display: inline;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}
.rf-dt {
  background-color: #FFFFFF;
  border-collapse: collapse;
  border-width: 0px;
  border-style: solid;
  border-color: #C4C0C9;
  empty-cells: show;
}
.leagueTable,
#leagueTable,
#intervalTable,
.statTable {
  text-align: center;
  font-size: 11px;
}
.leagueTable .smallFont,
#leagueTable .smallFont,
#intervalTable .smallFont,
.statTable .smallFont {
  font-size: 10px;
}
.leagueTable .team,
#leagueTable .team,
#intervalTable .team,
.statTable .team {
  text-align: left;
  text-transform: uppercase;
}
.leagueTable td,
#leagueTable td,
#intervalTable td,
.statTable td {
  padding: 3px;
}
.leagueTable .points,
#leagueTable .points,
#intervalTable .points,
.statTable .points {
  font-weight: bold;
}
.leagueTable thead td,
#leagueTable thead td,
#intervalTable thead td,
.statTable thead td {
  background-color: #b1c47a;
}
.statTable {
  font-size: 9px;
}
.evenBgColor {
  background-color: #f5ffbb;
}
.oddBgColor {
  background-color: #f6f7ff;
}
.fixedFixtures {
  position: fixed;
  left: 350px;
  top: 330px;
}
#statsRounds span {
  font-weight: bold;
}
#statsRounds table tr td.range {
  background-color: #efffe3;
}
#statsRounds table tr td {
  font-size: 20px;
}
#statsRounds table tr td div {
  font-size: 10px;
}
#statsMetadata {
  font-size: 10px;
}
#statsMetadata > div {
  position: fixed;
  background-color: #ffff00;
  z-index: 1000;
  width: 260px;
}
.fixturesCollationTable .result {
  max-width: 180px;
}
.fixturesCollationTable thead td {
  border: 1px solid #C4C0C9;
  font-size: 11px;
  text-align: center;
}
.fixturesCollationTable td {
  border: 1px solid #C4C0C9;
  padding: 2px 2px 2px 2px;
  font-size: 11px;
}
.tableRow .result {
  max-width: 180px;
}
.tableRow thead td {
  border: 1px solid #C4C0C9;
  padding: 4px 4px 4px 4px;
  font-size: 11px;
  text-align: center;
}
.tableRow td {
  border: 1px solid #C4C0C9;
  padding: 4px 4px 4px 4px;
  font-size: 11px;
}
.tableRowTimeline .popover {
  width: 435px;
}
.serieCollation {
  width: 30px;
}
.popover {
  max-width: 500px;
  min-width: 280px;
  width: auto;
}
/* klasy do określenia potencjału itp*/
.elite {
  background-color: #00ae11 !important;
}
.strong {
  background-color: #00e517 !important;
}
.ambitious {
  background-color: #f3eb00 !important;
}
.middle {
  background-color: #f6a623 !important;
}
.weak {
  background-color: #c2e5fe !important;
}
.greenSerie {
  background-color: #ade960;
}
.redSerie {
  background-color: #ff6d6d;
}
.yellowSerie {
  background-color: #fff789;
}
.selectedRow {
  background-color: gray;
}
.rowOffset {
  margin-left: -10px;
  margin-right: -10px;
}
.rowDate {
  max-width: 67px;
}
.rowDate100,
.rowWidth100 {
  min-width: 100px;
}
.rowWidth25 {
  min-width: 25px;
  width: 25px;
}
.rowWidth27 {
  min-width: 27px;
}
.rowWidth44 {
  min-width: 44px;
}
.rowWidth82 {
  min-width: 82px;
  width: 82px;
}
.rowWidth50 {
  min-width: 50px;
}
.rowWidth57 {
  min-width: 57px;
  width: 57px;
}
.rowWidth60 {
  min-width: 60px;
}
.rowWidth65 {
  width: 65px;
  min-width: 65px;
}
.kk-wait-for-load {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1050;
}
.kk-wait-for-load #initialize {
  background: url(/images/ajax-loader2.gif) center center no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
.kk-wait-for-load #contentWrap {
  display: none;
}
.row {
  padding-bottom: 5px;
}
.kk-wait-for-element-load {
  background: url(/images/ajax-loader2.gif) center center no-repeat;
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
}
.hideElement {
  display: none;
}
.colorHover td {
  background-color: #f1ff74 !important;
}
#tForm label {
  width: 60px;
}
.oddLight {
  background-color: #ffe0c6;
}
.evenLight {
  background-color: #e2e6ff;
}
.selectedTeamLight {
  background-color: #cdffe6;
}
.oddEvenCursor,
.pointerCursor {
  cursor: pointer;
}
.input50 {
  width: 50px;
}
.input150 {
  width: 150px;
}
.serieView {
  width: 15px;
  height: 20px;
}
.collationResult {
  width: 110px;
}
.teamFormSize17 {
  width: 17px;
  height: 17px;
}
.fixturesSimpleMetadata {
  font-weight: bold;
}
.scheduleGoalsTable {
  text-align: center;
}
.scheduleGoalsTable tr.strz {
  background-color: rgba(196, 255, 0, 0.3);
}
.scheduleGoalsTable tr.str {
  background-color: rgba(255, 143, 0, 0.24);
}
.scheduleGoalsTable tr td {
  border: 1px solid #ffa4ca;
}
.scheduleGoalsTable tr td.legend {
  padding: 2px;
}
.axis path {
  fill: none;
  stroke: #777;
  shape-rendering: crispEdges;
}
.axis text {
  font-family: Lato;
  font-size: 13px;
}
.navbar {
  margin-left: -15px;
  border-radius: 0px;
  position: fixed !important;
  border: 0px;
  width: 100%;
  z-index: 10000;
}
.navbar-default {
  background-color: #59b15e;
}
.whiteColor {
  color: white;
}
#messages {
  position: fixed;
  top: 60px;
  right: 10px;
  z-index: 20000;
}
.mini-form-icon {
  width: 15px;
  height: 15px;
  margin-left: 3px;
}
.team-heading {
  font-size: 20px;
  font-weight: bold;
}
.inOneLine {
  white-space: nowrap;
}
.potentialBox {
  width: 20px;
  height: 20px;
}
.preFixedHead {
  position: fixed;
  left: 255px;
  top: 50px;
  z-index: 100;
  background-color: #ffffff;
}
@media screen and (max-width: 1501px) {
  .predictBlockSearch {
    overflow-x: scroll;
  }
}
#editTeam div {
  padding: 4px;
}
.teamThumbnail {
  width: 40px;
  height: 40px;
}
.form-panel div {
  padding-top: 3px;
  padding-bottom: 3px;
}
.form-panel textarea {
  width: 100%;
  height: 300px;
  border: 1px solid black;
}
.form-panel input[type=text],
.form-panel input[type=email],
.form-panel input[type=password] {
  border: 1px solid black;
}
.form-panel button {
  padding: 10px;
  background-color: #99c75c;
}
.form-panel.default-panel label {
  width: 140px;
}
.form-panel.default-panel button {
  padding: 10px 20px 10px 20px;
}
.form-panel.default-panel .form-row-error {
  margin-left: 180px;
}
.inflateBlock {
  min-height: 1px;
}
.inflateTimelineBlock {
  min-width: 404px;
}
.matchLight {
  background-color: #99c75c;
}
select {
  min-width: 60px;
}
.topLabel {
  display: block;
}
.compareTeamsBlock {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fbff00;
}
.compareTeamsBlock a {
  background-color: #cee0ff;
}
.colorMarker > td {
  background-color: #fff608 !important;
}
.seasonLabel {
  width: 100px;
}
.teamMenu label {
  color: #ffffff;
}
.content {
  padding-top: 10px;
}
.inlineElement {
  display: inline-block;
}
#tfixturesSection.card-element {
  min-width: 1075px;
}
section#content > div {
  overflow-x: auto;
}
.term-prediction button {
  margin-top: 10px;
}
.term-prediction .matchElement {
  min-width: 300px;
}
.term-prediction table > thead td {
  font-weight: bold;
}
.term-prediction table td {
  padding: 2px;
}
.term-prediction table td.stat-element {
  white-space: nowrap;
}
.boldText {
  font-weight: bold;
}
.menu-switcher {
  font-size: 50px;
  color: white;
  padding-left: 9px;
  padding-top: 5px;
  position: fixed;
  z-index: 101;
  top: 0;
  background-color: #323232;
}
@media screen and (max-width: 1024px) {
  .menu-switcher {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .glyphicon-th-list:before {
    content: none;
  }
}
.resize-content .nav-menu {
  margin-left: 70px;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease-in-out;
}
.resize-content .custom-container {
  padding-left: 70px;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease-in-out;
}
.const-content .nav-menu {
  margin-left: 250px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease-in-out;
}
@media screen and (max-width: 1024px) {
  .const-content .nav-menu {
    height: 72px;
    margin-left: 0px;
  }
}
.const-content .custom-container {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease-in-out;
}
@media screen and (min-width: 1025px) {
  .const-content .custom-container {
    padding-left: 270px;
  }
}
.term {
  min-width: 1048px;
}
.team-fixtures {
  min-width: 1104px;
}
.extend-fixtures {
  min-width: 1350px;
}
.predict-fixtures {
  min-width: 1650px;
}
.visits-page {
  min-width: 620px;
}
.manage-bet {
  min-width: 700px;
}
.predict-ranking {
  min-width: 810px;
}
.collation-matches {
  min-width: 520px;
}
.min-width-600 {
  min-width: 600px;
}
.min-width-970 {
  min-width: 970px;
}
.copyright {
  color: white;
  position: absolute;
  bottom: 10px;
  left: 50px;
  z-index: -1;
}
#privacy p > span {
  margin-left: 40px;
}
.fastTeamMenu {
  background: #323232;
}
.image-wrapper-menu {
  position: relative;
  top: 24px;
  left: 20px;
  height: 0px;
}
.fbfunpagelogo {
  width: 20px;
  height: 20px;
}
.modal-hg {
  min-width: 992px;
  width: 1400px;
}
/* 16px */
/* 12px */
/* 14px */
/* 18px */
/* 24px */
/* 32px */
/* 36px */
.button-radius {
  border-radius: 20px;
  background: #99c75c;
  display: inline-block;
  line-height: 35px;
  color: #ffffff;
  font-size: 18px;
  padding: 0 10px;
}
.button-search {
  display: block;
}
.arrow {
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  margin-right: 5px;
  margin-left: 5px;
}
.arrow.high {
  border-bottom: 7px solid #1FD922;
}
.arrow.stabilize_up {
  border-bottom: 7px solid #D77B25;
}
.arrow.ascending {
  border-bottom: 7px solid #E3D552;
}
.arrow.stabilize_down {
  border-top: 7px solid #D1C300;
}
.arrow.descending {
  border-top: 7px solid #D57416;
}
.arrow.low {
  border-top: 7px solid #D32831;
}
.arrow.arrow-big {
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
}
.arrow.arrow-big.high {
  border-bottom: 14px solid #1FD922;
}
.arrow.arrow-big.stabilize_up {
  border-bottom: 14px solid #D77B25;
}
.arrow.arrow-big.ascending {
  border-bottom: 14px solid #E3D552;
}
.arrow.arrow-big.stabilize_down {
  border-top: 14px solid #D1C300;
}
.arrow.arrow-big.descending {
  border-top: 14px solid #D57416;
}
.arrow.arrow-big.low {
  border-top: 14px solid #D32831;
}
.card-element {
  width: 98%;
  margin: 50px auto;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.glyphicon-thumbs-up {
  color: #008000;
  font-size: 17px;
}
.glyphicon-thumbs-down {
  color: darkred;
  font-size: 17px;
}
.team-component > div {
  vertical-align: top;
  display: inline-block;
}
.team-component .team-simple > div {
  display: inline-block;
}
.team-component .potential-wrapper {
  width: 5px;
  height: 54px;
}
.team-component .attr {
  font-size: 12px;
}
.team-component .vs {
  vertical-align: top;
}
.potential-wrapper {
  width: 5px;
  height: 15px;
  display: inline-block;
}
.popover-content .home-shooters {
  float: left;
  margin-left: 30px;
  margin-right: 50px;
  min-height: 1px;
  min-width: 50px;
}
.popover-content .away-shooters {
  float: left;
}
.nav-pagination {
  text-align: center;
}
.nav-pagination .pagination li span {
  color: #4a4a4a;
}
.nav-pagination .pagination .active span {
  background-color: #99c75c;
  border-color: #99c75c;
}
.filter-block > div {
  padding-bottom: 10px;
}
.filter-block select {
  font-size: 18px;
}
.clear {
  clear: both;
  height: 1px;
}
.custom-row {
  position: relative;
  margin: 0;
}
.contentWrap {
  background: #fcfcfc;
}
.table-wrapper {
  margin: 25px 0;
}
.small-width {
  width: 25%;
}
.medium-width {
  width: 40%;
}
.middle-width {
  width: 60%;
}
.max-width {
  width: 100%;
}
.margin-standard-top {
  margin-top: 25px;
}
.margin-standard-left {
  margin-left: 25px;
}
.margin-standard-right {
  margin-right: 25px;
}
.margin-standard-bottom {
  margin-bottom: 25px;
}
.custom-container {
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .custom-container {
    padding: 85px 25px 50px 275px;
  }
}
.data-group .collation-title {
  text-align: center;
}
.side {
  width: 50%;
}
@media screen and (max-width: 1130px) {
  .side {
    width: 100%;
    padding: 0;
  }
}
.side.side-left {
  float: left;
  padding-right: 25px;
}
@media screen and (max-width: 1130px) {
  .side.side-left {
    padding-right: 0;
  }
}
.side.side-right {
  float: right;
  padding-left: 25px;
}
@media screen and (max-width: 1130px) {
  .side.side-right {
    padding-left: 0;
  }
}
.side.collation-side {
  padding-top: 25px;
  padding-bottom: 25px;
}
@media screen and (max-width: 1300px) {
  .const-content .side {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .const-content .side.side-left {
    padding-right: 0;
  }
}
@media screen and (max-width: 1300px) {
  .const-content .side.side-right {
    padding-left: 0;
  }
}
.cursor-pointer:hover {
  cursor: pointer;
}
.uppercase-letter {
  text-transform: uppercase;
}
.bold-element {
  font-weight: bold;
}
.form-row-error {
  color: #a10a07;
  display: block;
}
*,
button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: 'Raleway', sans-serif;
}
body {
  background: #fcfcfc;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.sidebar-menu {
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background: #323232;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
}
@media screen and (max-width: 1024px) {
  .sidebar-menu {
    position: relative;
    width: 100%;
    z-index: 95;
  }
}
.sidebar-menu .menu-panel {
  border: none;
  margin-bottom: -3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  background: #272727;
}
.sidebar-menu .menu-panel .panel-heading {
  padding: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.sidebar-menu .panel-title > a {
  display: block;
  transition: background 500ms;
  padding: 20px 10px 20px 20px;
  color: #bababa;
  border-left: 4px solid #bbe880;
  background: url("/images/arrow-menu-active.png") no-repeat right 10px center;
}
.sidebar-menu .panel-title > a.collapsed {
  border-left: 4px solid transparent;
  background: url("/images/arrow-menu.png") no-repeat right 10px center;
}
.sidebar-menu .panel-title > a:hover {
  border-left: 4px solid #bbe880;
}
.sidebar-menu .panel-body {
  border: none !important;
  padding: 0;
}
.sidebar-menu .panel-body .sidebar-submenu {
  margin-bottom: 0;
}
.sidebar-menu .panel-body .sidebar-submenu li {
  margin-bottom: 2px;
  font-size: 12px;
}
.sidebar-menu .panel-body .sidebar-submenu li .circle-submenu {
  width: 8px;
  height: 8px;
  display: inline-block;
  border: 1px solid #99c75c;
  border-radius: 50%;
  margin-right: 10px;
}
.sidebar-menu .panel-body .sidebar-submenu li a {
  display: block;
  cursor: pointer;
  width: 100%;
  color: #9b9b9b;
  background: #2d2d2d;
  padding: 10px 10px 10px 40px;
}
.sidebar-menu .panel-body .sidebar-submenu li a:hover .circle-submenu {
  background: #99c75c;
}
.logo-wrapper {
  width: 100%;
  padding: 0 20px;
  margin: 80px 0 40px 0;
}
.logo-wrapper img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .logo-wrapper img {
    max-width: 669px;
  }
}
.sidebar-active {
  margin-left: 250px;
}
.nav-menu {
  margin-left: 250px;
  background: #fcfcfc;
  position: fixed;
  border-bottom: 1px solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  z-index: 98;
  min-width: 400px;
}
@media screen and (max-width: 1024px) {
  .nav-menu {
    margin-left: 0px;
    position: relative;
  }
}
.nav-menu .custom-button-wrapper {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  float: left;
}
.nav-menu .custom-select-wrapper {
  border-right: 1px solid #e6e6e6;
  float: left;
}
.nav-menu .form-control-custom {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-bottom-color: #99c75c;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  background: #ffffff;
  height: 60px;
}
.nav-menu select.form-control-custom {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 150px;
  font-size: 16px;
  text-align: center;
  text-align-last: center;
  background: url("/images/shape1.png") no-repeat right 10px center;
}
.nav-menu button.form-control-custom {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 150px;
  font-size: 16px;
  text-align: center;
  text-align-last: center;
  background: transparent;
}
.nav-menu a.custom-link {
  font-size: 16px;
  text-align: center;
  display: block;
  padding: 15px;
  color: #555;
}
.nav-menu .nav-btn {
  height: 71px;
}
.menu-col {
  width: 50%;
  float: left;
  position: relative;
}
.menu-col .login-form-wrapper {
  width: 270px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  top: 77px;
  right: 15px;
  background: #ffffff;
  padding: 10px;
}
@media screen and (max-width: 500px) {
  .menu-col .login-form-wrapper {
    top: 150px;
  }
}
.menu-col .login-form-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  margin-right: 5px;
  margin-left: 5px;
  border-bottom: 7px solid #ffffff;
  top: -7px;
  right: 7px;
}
.menu-col .login-form-wrapper:before.high {
  border-bottom: 7px solid #1FD922;
}
.menu-col .login-form-wrapper:before.stabilize_up {
  border-bottom: 7px solid #D77B25;
}
.menu-col .login-form-wrapper:before.ascending {
  border-bottom: 7px solid #E3D552;
}
.menu-col .login-form-wrapper:before.stabilize_down {
  border-top: 7px solid #D1C300;
}
.menu-col .login-form-wrapper:before.descending {
  border-top: 7px solid #D57416;
}
.menu-col .login-form-wrapper:before.low {
  border-top: 7px solid #D32831;
}
.menu-col .login-form-wrapper:before.arrow-big {
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
}
.menu-col .login-form-wrapper:before.arrow-big.high {
  border-bottom: 14px solid #1FD922;
}
.menu-col .login-form-wrapper:before.arrow-big.stabilize_up {
  border-bottom: 14px solid #D77B25;
}
.menu-col .login-form-wrapper:before.arrow-big.ascending {
  border-bottom: 14px solid #E3D552;
}
.menu-col .login-form-wrapper:before.arrow-big.stabilize_down {
  border-top: 14px solid #D1C300;
}
.menu-col .login-form-wrapper:before.arrow-big.descending {
  border-top: 14px solid #D57416;
}
.menu-col .login-form-wrapper:before.arrow-big.low {
  border-top: 14px solid #D32831;
}
.menu-label {
  height: 11px;
  padding-top: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #9b9b9b;
  text-align: center;
}
.auth-wrapper-menu {
  position: relative;
}
.auth-wrapper-menu .single-box {
  float: left;
}
.auth-wrapper-menu .auth-box {
  float: right;
  margin: 12px 20px 0 0;
}
.auth-wrapper-menu .auth-box .icon-login {
  display: block;
  width: 37px;
  float: left;
  cursor: pointer;
}
.auth-wrapper-menu .auth-box .icon-login:hover {
  opacity: 0.7;
}
.auth-wrapper-menu .auth-box .circle-dropdown {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #4a4a4a;
  border: 1px solid #4a4a4a;
  line-height: 13px;
  font-size: 28px;
  margin: 6px 0 0 10px;
  cursor: pointer;
}
.auth-wrapper-menu .auth-box .circle-dropdown:hover {
  opacity: 0.7;
}
.animate-show-hide.ng-hide {
  opacity: 0;
}
.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 0.5s;
}
.hided-sidebar {
  background: #323232;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 1025px) {
  .hided-sidebar {
    width: 67px;
  }
}
.search-slider {
  padding-bottom: 10px;
}
.search-slider .custom-range-slider {
  text-align: center;
  vertical-align: middle;
}
.search-slider .custom-range-slider .range-element {
  display: inline-block;
  width: 80%;
}
.search-slider .custom-range-slider .ui-slider-horizontal {
  height: 4px;
}
.search-slider .custom-range-slider .ui-widget-content {
  background: #e6e6e6;
  border: none;
}
.search-slider .custom-range-slider .ui-slider .ui-slider-handle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.search-slider .custom-range-slider .ui-widget-header {
  background: #99c75c;
}
.search-slider .custom-range-slider .ui-state-default {
  background: #99c75c;
  border: none;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.24);
}
.search-slider .custom-range-slider .range-value {
  margin: 0 10px;
  color: #9b9b9b;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 40%;
  border: 1px solid #e6e6e6;
  line-height: 35px;
  text-align: center;
}
.table-custom {
  border-collapse: separate;
  width: 100%;
}
.common-table {
  border-radius: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}
.common-table .table-title {
  background: #f7f7f7;
  font-size: 18px;
  color: #4a4a4a;
}
.common-table .table-title .table-cell {
  border: 1px solid #f1f1f1;
  padding: 10px;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 10px;
}
.common-table .table-header {
  background: #ffffff;
}
.common-table .table-header td {
  font-size: 12px;
  color: #4a4a4a;
  padding: 3px 5px;
}
.common-table .table-header td.summary {
  color: #99c75c;
}
.common-table .table-header.header-element-2 td {
  padding: 15px 5px 3px 5px;
}
.common-table .table-row:first-child:not(.not-highlight) {
  background: #efefef;
  /*todo */
}
.common-table .table-row:nth-child(even) {
  background: #f9f9f9;
}
.common-table .table-row td {
  font-size: 12px;
  padding: 10px 5px;
}
.common-table .table-row td.simulation {
  padding: 2px 2px;
}
.common-table .table-row td.team-name {
  font-size: 14px;
  font-weight: bold;
}
.common-table .table-row td.team-name .no-bold {
  font-weight: normal;
}
.common-table .table-row td.team-name a {
  color: #4a4a4a;
}
.common-table .table-row td.points {
  font-weight: bold;
  font-size: 14px;
}
.common-table .table-row td .simulated-percentage-points {
  float: right;
  margin-right: 10px;
}
.common-table .table-row td.popover-cell {
  width: 25%;
}
.common-table .header-1 td {
  color: #737373;
  /*todo */
}
.common-table .header-2 td {
  font-weight: bold;
}
.common-table .header-3 td {
  font-weight: bold;
}
.common-table.collation-table {
  padding-left: 20px;
}
.common-table.collation-table .table-row:not(.collationTeam) {
  opacity: 0.5;
}
.arrow-cell {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 20px;
}
.arrow-cell .arrow {
  margin-right: 0;
  margin-left: 0;
}
.potential-box {
  width: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.bordered-vertical {
  border-left: 2px solid;
  border-right: 2px solid;
}
.bordered-vertical.color-1 {
  border-color: #ffffff;
  background: rgba(255, 255, 255, 0.8);
  /*todo */
}
.bordered-vertical-half {
  border-right: 2px solid;
}
.bordered-vertical-half.color-1 {
  border-color: #ffffff;
}
.bordered-vertical-half.color-2 {
  border-color: rgba(233, 233, 233, 0.27);
  /*todo */
}
.vertical-center td:not(.clear-vertical) {
  text-align: center;
}
.wider-cell-left {
  padding-left: 20px !important;
}
.wider-cell-right {
  padding-right: 20px !important;
}
.row-series .bordered-cell {
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}
.row-series .bordered-cell:last-child {
  border-right: 2px solid #ffffff;
}
.row-series:last-child .bordered-cell {
  border-bottom: 2px solid #ffffff;
}
.collationTeam {
  background-color: #ffffff !important;
  -webkit-box-shadow: inset 0px -2px 8px 0px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: inset 0px -2px 8px 0px rgba(0, 0, 0, 0.37);
  box-shadow: inset 0px -2px 8px 0px rgba(0, 0, 0, 0.37);
}
.collationTeam .bordered-vertical {
  background: transparent;
}
.custom-tabs-wrapper .nav-tabs {
  border: 1px solid #e6e6e6;
}
.custom-tabs-wrapper .nav-tabs > li {
  margin-bottom: 0;
}
.custom-tabs-wrapper .nav-tabs > li > a {
  margin-right: 0;
  border-radius: 0;
  padding: 25px;
  color: #9b9b9b;
  border: none;
}
.custom-tabs-wrapper .nav-tabs > li > a:hover {
  background: transparent;
  cursor: pointer;
}
.custom-tabs-wrapper .nav-tabs > li > a:hover:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #99c75c;
}
.custom-tabs-wrapper .nav-tabs > li.active > a {
  border: none;
}
.custom-tabs-wrapper .nav-tabs > li.active > a:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #99c75c;
}
.custom-tabs-wrapper .nav-tabs > li.active > a .tabnav-title {
  color: #99c75c;
}
.custom-tabs-wrapper .tabnav-title {
  font-size: 14px;
  font-weight: 500;
}
.timebox {
  height: 80px;
  width: 200px;
  float: left;
  margin-left: 1px;
}
.timeline {
  width: 200px;
  display: inline-block;
  background-color: #99c75c;
  height: 7px;
}
.timeline-minute {
  display: block;
}
.timeline-element-top {
  display: inline-block;
  float: left;
  border: 1px solid transparent;
  margin-top: 6px;
  font-family: Arial, Verdana, sans-serif;
  font-size: 11px;
}
.timeline-element-bottom {
  display: inline-block;
  margin-top: -2px;
  float: left;
  border: 1px solid transparent;
  font-family: Arial, Verdana, sans-serif;
  font-size: 11px;
}
.timeline-goal-top {
  width: 10px;
  display: inline-block;
  background: url("/images/goal-icon.gif") no-repeat 0px 0px;
  height: 10px;
  margin-top: 0px;
  border: 1px solid transparent;
}
.timeline-goal-bottom {
  width: 10px;
  display: inline-block;
  background: url("/images/goal-icon.gif") no-repeat 0px 0px;
  height: 10px;
}
.timeline-redcard-top {
  width: 10px;
  display: inline-block;
  background: url("/images/redcard-icon.gif") no-repeat 0px 0px;
  height: 10px;
  margin-top: 0px;
  border: 1px solid transparent;
}
.timeline-redcard-bottom {
  width: 10px;
  display: inline-block;
  background: url("/images/redcard-icon.gif") no-repeat 0px 0px;
  height: 10px;
}
.timeline-element-up {
  width: 200px;
  height: 36px;
  float: left;
  /*border: 1px solid red;*/
}
.timeline-element-down {
  width: 200px;
  height: 36px;
  float: left;
  /*border: 1px solid red;*/
}
.carousel-box {
  padding: 0 50px;
}
.carousel-box > ul[rn-carousel] > li {
  overflow-x: auto;
}
.rn-carousel-control {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #99c75c;
  background: #ffffff;
  transition: background 500ms;
}
.rn-carousel-control:before {
  text-align: center;
  display: block;
  color: #99c75c;
  font-weight: bold;
}
.rn-carousel-control:hover {
  border: 2px solid #ffffff;
  background: #99c75c;
}
.rn-carousel-control:hover:before {
  color: #ffffff;
}
.element-with-tooltip .tooltip {
  font-size: 14px;
}
.element-with-tooltip .tooltip-inner {
  padding: 10px;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(140, 140, 140, 0.5);
  color: #4a4a4a;
}
.element-with-tooltip .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  border-width: 8px 8px 0;
  border-top-color: #ffffff;
}
.element-with-tooltip .tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 8px;
  margin-bottom: -8px;
  border-width: 8px 8px 0;
  border-top-color: #ffffff;
}
.element-with-tooltip .tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 8px;
  margin-bottom: -8px;
  border-width: 8px 8px 0;
  border-top-color: #ffffff;
}
.element-with-tooltip .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -8px;
  border-width: 8px 8px 8px 0;
  border-right-color: #ffffff;
}
.element-with-tooltip .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -8px;
  border-width: 8px 0 8px 8px;
  border-left-color: #ffffff;
}
.element-with-tooltip .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -8px;
  border-width: 0 8px 8px;
  border-bottom-color: #ffffff;
}
.element-with-tooltip .tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 8px;
  margin-top: -8px;
  border-width: 0 8px 8px;
  border-bottom-color: #ffffff;
}
.element-with-tooltip .tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 8px;
  margin-top: -8px;
  border-width: 0 8px 8px;
  border-bottom-color: #ffffff;
}
.tooltip-content .round-number {
  margin-bottom: 10px;
}
.tooltip-content .teams-name {
  font-size: 12px;
}
.tooltip-content .match-result {
  font-size: 24px;
}
.box-collation-wrapper {
  margin: 35px auto 25px auto;
}
.collation-title .team,
.collation-title .versus {
  display: inline-block;
  font-weight: bold;
  font-size: 36px;
}
.collation-title .team-score {
  font-size: 18px;
}
.collation-title .versus {
  margin: 0 25px;
}
.home-group {
  text-align: center;
  line-height: 46px;
  font-family: 'Raleway';
  min-width: 964px;
}
.home-group .team {
  font-size: 42px;
  font-weight: 900;
  color: #323232;
  text-align: center;
  display: inline-block;
}
.home-group .team-changer {
  display: inline-block;
}
.home-group .team-changer-left {
  background: url("/images/left-arrow-changer.png") no-repeat 0px 0px;
  height: 108px;
  width: 70px;
  transform: scale(0.5, 0.5);
  position: relative;
  top: 40px;
}
.home-group .team-changer-right {
  background: url("/images/right-arrow-changer.png") no-repeat 0px 0px;
  height: 116px;
  width: 72px;
  transform: scale(0.5, 0.5);
  position: relative;
  top: 41px;
}
.home-group .team-hint {
  height: 29px;
  width: 29px;
  border-radius: 29px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  top: 45px;
}
.home-group .team-hint .label {
  font-size: 14px;
  position: relative;
  color: #bababa;
  top: -25px;
  right: 9px;
  padding: 0;
  font-weight: normal;
}
.home-group .team-hint .count {
  top: -9px;
  position: relative;
}
.home-group .team-hint-left {
  background-color: #4a4a4a;
  margin-right: 30px;
}
.home-group .team-hint-right {
  margin-left: 30px;
  background-color: #99c75c;
}
.home-group .next-game {
  font-size: 16px;
  color: #cdcdcd;
}
.home-group .next-game-section {
  line-height: 20px;
}
.home-group .next-game-date {
  font-size: 18px;
  color: #99c75c;
  font-weight: bold;
}
.home-group .interesting-facts {
  padding-top: 15px;
}
.home-group .interesting-facts-section {
  line-height: 30px;
  display: inline-flex;
  width: 100%;
}
.home-group .interesting-facts-section .vs-team {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}
.home-group .interesting-facts-section .predict-box {
  color: #bababa;
}
.home-group .interesting-facts-section .predict-box .type {
  color: #99c75c;
  font-weight: bold;
}
.home-group .interesting-facts-section .row-fact {
  font-size: 12px;
  color: #a5a5a5;
  display: block;
}
.home-group .interesting-facts-section .row-fact.for-home-team {
  text-align: right;
}
.home-group .interesting-facts-section .row-fact.for-home-team::after {
  height: 7px;
  width: 7px;
  background-color: #d8d8d8;
  border-radius: 7px;
  content: "";
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  position: relative;
  bottom: 0px;
}
.home-group .interesting-facts-section .row-fact.for-away-team {
  text-align: left;
}
.home-group .interesting-facts-section .row-fact.for-away-team::before {
  height: 7px;
  width: 7px;
  background-color: #d8d8d8;
  border-radius: 7px;
  content: "";
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  bottom: 0px;
}
.home-group .section-padding {
  padding-top: 25px;
  padding-bottom: 15px;
}
.home-group .home-footer {
  background: url("/images/home-footer2.png") no-repeat 0px 0px;
  background-size: 100%;
  width: 100%;
  min-height: 340px;
}
.base-flex-row {
  display: flex;
}
.late-goals-block {
  width: 100%;
  min-width: 700px;
  position: static;
  display: block;
  overflow: hidden;
}
.late-goals-block table {
  display: block;
  float: left;
  position: relative;
}
.late-goals-block table thead {
  text-align: center;
}
.late-goals-block table thead td {
  height: 38px;
}
.late-goals-block table tbody {
  text-align: center;
}
.late-goals-block table tbody td {
  font-size: 12px;
  height: 20px;
  padding: 10px 5px;
}
.late-goals-block .include-pre-table {
  display: block;
  float: left;
}
.late-goals-block .include-pre-table tbody td {
  height: 20px;
}
.late-goals-block .scrollable-table {
  width: calc(100% - 175px);
  position: relative;
  float: left;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  border-radius: 0;
}
.late-goals-block .scrollable-table .borderLine {
  border-right: 1px #000000 solid;
}
.late-goals-block .scrollable-table .leftBorderLine {
  border-left: 1px #000000 solid;
}
.half-ratio h2 {
  text-align: center;
}
.half-ratio .match-panel {
  box-shadow: 0 0 7px 0 #47596a;
  margin-bottom: 10px;
  margin-top: 2px;
  padding: 20px 20px 5px 20px;
  display: flex;
  min-width: 222px;
}
.half-ratio .match-panel .serie-box {
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  position: relative;
  margin-bottom: 17px;
  margin-top: 3px;
  box-shadow: 0 0 7px 0 #47596a;
}
.round-title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0 10px 0;
}
.term-container {
  text-align: center;
  padding-top: 10px;
}
.winTeam {
  color: #99c75c;
  font-weight: bold;
}
.head-element {
  font-weight: bold;
  color: #4a4a4a;
  padding: 20px 0;
}
.row-body-element {
  height: 100%;
  position: relative;
  background: #ffffff;
  border-left: 8px solid #ffffff;
  border-right: 8px solid #ffffff;
}
.row-body-element.highlight-element {
  background: #f9f9f9;
}
.row-body-element.compare-row:not(.matchLight) {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  opacity: 0.3;
}
.row-body-element.matchLight {
  border-left: 8px solid #99c75c;
  border-right: 8px solid #99c75c;
}
.cell-element {
  text-align: center;
  float: left;
}
.cell-element.element-small {
  width: 15%;
}
.cell-element.element-less-smaller {
  width: 7%;
}
.cell-element.element-smaller {
  width: 10%;
}
.cell-element.element-smallest {
  width: 5%;
}
.cell-element.element-medium {
  width: 20%;
}
.cell-element.element-more-medium {
  width: 25%;
}
.cell-element.element-less-normal {
  width: 30%;
}
.cell-element.element-normal {
  width: 35%;
}
.cell-element.element-less-big {
  width: 38%;
}
.cell-element.element-big {
  width: 40%;
}
.table-mock {
  display: table;
  width: 100%;
  min-height: 90px;
}
.table-mock .cell-mock {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.table-mock .cell-mock.potential-wrapper {
  width: 1%;
}
.table-mock .cell-mock.versus {
  width: 8%;
}
.table-mock .cell-mock.team-cell {
  width: 45%;
  font-weight: bold;
}
.table-mock .cell-mock.team-cell.right-side-align {
  text-align: right;
}
.table-mock .cell-mock.team-cell.left-side-align {
  text-align: left;
}
